import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Success = () => {
  return (
    <Layout>
      <SEO title='404' description='Success!' />
      <main className='error-page'>
        <div className=''>
          <h1>You're in!</h1>
          <p>Thanks for signing up and welcome to Lancaster's digital community.</p>
          <div class="success-animation">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
          <Link to='/' className='btn'>
            Home
          </Link>
        </div>
      </main>
    </Layout>
  )
}
export default Success
